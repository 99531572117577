form label {
  margin: 0 0 10px;
}

.cmp-form-options {
  display: grid;
  padding: 0;
  border: none;
}

// == hide legend and help message
.cmp-form-options__legend,
.cmp-form-options__help-message {
  display: none;
}

.cmp-form-text label {
  margin-bottom: 0;
}

// =============== Drop Down Styles =================

// == drop down
.cmp-form-options--drop-down {
  position: relative;
  margin: 0.75em 0.5em 0.5em 0.5em;
  padding: 0;
}

.cmp-form-options__field--drop-down {
  display: none;
}

.select-selected {
  min-width: 256px;
  padding: rem(12) rem(16);
  color: $primary-color-softgrey;
  border: 1px solid $color-shade-5;
  border-radius: 8px;
  line-height: 150%;
  letter-spacing: 0.08px;
  cursor: pointer;

  &:after {
    display: inline-block;
    float: right;
    width: 24px;
    height: 24px;
    margin: 0;
    vertical-align: -25%;
    background-color: $text-color-black;
    content: '';
    mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTRsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE1TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==');
    mask-size: cover;
  }

  &:hover {
    border-color: $border-colors-dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $primary-color-white, 0 0 0 5px $primary-color-black, 0 0 0 7px $primary-color-white;
    outline: none;
  }

  &.invalid-input {
    background: $container-error-color;
    color: $main-error-color;
    border-color: $main-error-color;

    &:after {
      background-color: $main-error-color;
    }
  }
}

.select-arrow-active {
  margin: -0.0625em;
  border: 0.125em solid $color-accent-darker;
  &:after {
    display: inline-block;
    float: right;
    width: 24px;
    height: 24px;
    margin: 0;
    vertical-align: -25%;
    background-color: $color-shade-3;
    content: '';
    mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTI1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUzMC41MTUgOTUuODI4KSByb3RhdGUoLTkwKSIvPjwvZz48L3N2Zz4=');
    mask-size: cover;
  }
}

.select-items {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-top: 0.5em;
  padding: 0.25em 0;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $border-colors-dark;
  background: $primary-color-white;
}

.select-hide {
  display: none;
}

.select-items div {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 rem(16);
  font-size: rem(16);
  font-style: normal;
  font-weight: 340;
  line-height: 110%;
  letter-spacing: -0.08px;
  color: $text-color-black;
  cursor: pointer;

  &:nth-child(even) {
    background: $primary-color-lightgrey;
  }

  &:not(.same-as-selected) {
    &:hover {
      background: $primary-color-lightgrey;
    }
  }
}

.same-as-selected {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 24px;
    right: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNjY2OTUgMTUuNTE5NUwxOC40NjkgNi43MTc0OUMxOC41NjYzIDYuNjIwMTUgMTguNjgxIDYuNTY4MjMgMTguODEzMiA2LjU2MTczQzE4Ljk0NTIgNi41NTU0IDE5LjA2NjMgNi42MDczMiAxOS4xNzY1IDYuNzE3NDlDMTkuMjg2OCA2LjgyNzgyIDE5LjM0MiA2Ljk0NjczIDE5LjM0MiA3LjA3NDIzQzE5LjM0MiA3LjIwMTkgMTkuMjg2OCA3LjMyMDgyIDE5LjE3NjUgNy40MzA5OUwxMC4yMzI1IDE2LjM4MUMxMC4wNzA4IDE2LjU0MjUgOS44ODIyOSAxNi42MjMyIDkuNjY2OTUgMTYuNjIzMkM5LjQ1MTYyIDE2LjYyMzIgOS4yNjMxMiAxNi41NDI1IDkuMTAxNDUgMTYuMzgxTDUuMDUxNDUgMTIuMzMxQzQuOTU0MTIgMTIuMjMzNyA0LjkwMzIgMTIuMTE4IDQuODk4NyAxMS45ODRDNC44OTQyIDExLjg1IDQuOTQ3MTIgMTEuNzI3OCA1LjA1NzQ1IDExLjYxNzVDNS4xNjc2MiAxMS41MDczIDUuMjg2NDUgMTEuNDUyMiA1LjQxMzk1IDExLjQ1MjJDNS41NDE2MiAxMS40NTIyIDUuNjYwNTQgMTEuNTA3MyA1Ljc3MDcgMTEuNjE3NUw5LjY2Njk1IDE1LjUxOTVaIiBmaWxsPSIjMjEyMTI0Ii8+Cjwvc3ZnPgo=");
  }
}

// =============== Check Box Styles =================
.cmp-form-options--checkbox {
  margin: 0;
  padding: 0;
}

// == entire checkbox/radio with text
.cmp-form-options__field-label {
  width: fit-content;
  min-width: 238px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    .cmp-form-options__field--checkbox,
    .cmp-form-options__field--radio {
      border-width: 2px;
    }
  }

  &:focus {
    border: 2px solid;
    border-radius: 8px;
    box-shadow: 0 0 0 3px #000000, 0 0 0 5px #FFFFFF;
  }
}

// == radio checkbox
.cmp-form-options__field--radio,
.cmp-form-options__field--checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  appearance: none;
  border: 1px solid $border-colors-dark;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  &:checked,
  &.invalid-input {
    border-width: 2px;

    &::before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $border-colors-dark;
      border-radius: 50%;
    }
  }

  &.invalid-input {
    color: $main-error-color;
    border-color: $main-error-color;

    &::before {
      width: 100%;
      height: 100%;
      background: $container-error-color;
    }
  }
}

// == square checkbox
.cmp-form-options__field--checkbox {
  border-radius: 4px;

  &:checked {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: $border-colors-dark;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMTM0MTUgMTIuODE1NUwxNS4xNzU3IDUuNzczOTRDMTUuMjUzNiA1LjY5NjA3IDE1LjM0NTQgNS42NTQ1NCAxNS40NTExIDUuNjQ5MzRDMTUuNTU2NyA1LjY0NDI3IDE1LjY1MzYgNS42ODU4MSAxNS43NDE3IDUuNzczOTRDMTUuODMgNS44NjIyMSAxNS44NzQxIDUuOTU3MzQgMTUuODc0MSA2LjA1OTM0QzE1Ljg3NDEgNi4xNjE0NyAxNS44MyA2LjI1NjYxIDE1Ljc0MTcgNi4zNDQ3NEw4LjU4NjU1IDEzLjUwNDdDOC40NTcyMSAxMy42MzM5IDguMzA2NDEgMTMuNjk4NSA4LjEzNDE1IDEzLjY5ODVDNy45NjE4OCAxMy42OTg1IDcuODExMDggMTMuNjMzOSA3LjY4MTc1IDEzLjUwNDdMNC40NDE3NSAxMC4yNjQ3QzQuMzYzODggMTAuMTg2OSA0LjMyMzE1IDEwLjA5NDMgNC4zMTk1NSA5Ljk4NzE0QzQuMzE1OTUgOS44Nzk5NCA0LjM1ODI4IDkuNzgyMjEgNC40NDY1NSA5LjY5Mzk0QzQuNTM0NjggOS42MDU4MSA0LjYyOTc1IDkuNTYxNzQgNC43MzE3NSA5LjU2MTc0QzQuODMzODggOS41NjE3NCA0LjkyOTAxIDkuNjA1ODEgNS4wMTcxNSA5LjY5Mzk0TDguMTM0MTUgMTIuODE1NVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
      border-radius: 0;
    }
  }

  &.invalid-input {
    &::before {
      border-radius: 0;
    }
  }
}

// == text next to checkbox
.cmp-form-options__field-description {
  margin: 0;
  color: $color-text;
}
