.teaser--hero-slim {
  &.teaser--reverse {
    .cmp-teaser__image {
      order: 2;
    }

    .cmp-teaser__content {
      order: 1;
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: rem(40) rem(8);
    gap: 24px;

    @media screen and (min-width: $breakpoint--desktop) {
      padding: rem(40) rem(88) rem(24) rem(88);
      gap: 32px;
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
  }

  .cmp-teaser__image {
    width: 100%;
    height: rem(420);
    order: 1;

    @media screen and (min-width: $breakpoint--desktop) {
      width: 100%;
    }

    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      height: rem(420);
      object-fit: cover;
      border-radius: rem(20);
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    order: 2;

    @media screen and (min-width: $breakpoint--desktop) {
      width: 78.007%;
      bottom: 104px;
      gap: 32px;
    }

    .cmp-teaser__pretitle {}

    .cmp-teaser__title,
    .cmp-teaser__title a {
      font-size: rem(40);
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      color: $text-color-black;
      text-transform: none;
      text-decoration: none;
      margin: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(70);
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      font-size: rem(24);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.12px;
      color: $text-color-black;
      margin: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(34);
        letter-spacing: 0.17px;
      }
    }
  }

  .cmp-teaser__action-container {
    display: flex;
    flex-direction: column;
    gap: rem(8);

    @media screen and (min-width: $breakpoint--desktop) {
      font-size: rem(70);
      flex-direction: row;
      align-items: center;
    }
  }

  .cmp-teaser__action-link {
    @extend %cta;

    &:first-child,
    &:nth-child(2) {
      margin: 0;
      padding: rem(12) rem(40);
    }

    &.cmp-teaser__action-link--icon-only {
      @extend %cta--icon-only;
      text-indent: -9999999px;
      padding: rem(14);
    }
  }
}
