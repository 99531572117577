.teaser--hero {
  * {
    box-sizing: border-box;
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: $breakpoint--desktop) {
      flex-direction: row;
    }
  }

  .cmp-teaser__image {
    width: 100%;
    height: rem(420);
    position: relative;
    margin: 0 auto;

    // Dark overlay
    /*&::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);;
      z-index: 1;
    }*/

    @media screen and (min-width: $breakpoint--desktop) {
      width: 100%;
      height: rem(800);
    }

    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      height: rem(420);
      object-fit: cover;

      @media screen and (min-width: $breakpoint--desktop) {
        height: rem(800);
      }
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    padding: rem(24) rem(14);
    gap: rem(16);
    z-index: 2;

    @media screen and (min-width: $breakpoint--desktop) {
      position: absolute;
      top: 50%;
      left: rem(88);
      //max-width: 68.5%;
      max-width: 50%;
      padding: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      gap: rem(24);
    }

    .cmp-teaser__pretitle {}

    .cmp-teaser__title {
      font-size: rem(40);
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 34px */
      color: $text-color-black;
      text-transform: none;
      margin: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(70);
        color: $primary-color-white;
        margin-left: rem(-4);
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      font-size: rem(24);
      font-style: normal;
      font-weight: 340;
      line-height: 150%; /* 30px */
      letter-spacing: 0.12px;
      color: $text-color-black;
      margin: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(34);
        letter-spacing: 0.17px;
        color: $primary-color-white;
      }
    }
  }

  .cmp-teaser__action-container {
    @media screen and (min-width: $breakpoint--desktop) {
      margin-top: rem(16);
    }
  }

  .cmp-teaser__action-container {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    @media screen and (min-width: $breakpoint--desktop) {
      font-size: rem(70);
      flex-direction: row;
    }
  }

  .cmp-teaser__action-link {
    @extend %cta;
    margin: 0;
  }

  .cmp-teaser__action-link--primary {
    @media screen and (max-width: 899px) {
      &:hover {
       border: 1px solid $primary-color-black;
      }
    }
  }
}


.teaser--hero.teaser--hero-dark-text {
  .cmp-teaser__title,  .cmp-teaser__description,
  .cmp-teaser__description p {
    color: $text-color-black;
  }
}
