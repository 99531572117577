
//== Defaults

html, body {
  position: relative;
  max-width: rem(1920);
  margin: 0 auto;
  color: $color-text;
  font-size: $font-size;
  font-family: 'Antarctica', 'defaultFont', sans-serif;
  line-height: $font-height;
}

a {
  color: $color-link;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

.hidden {
  display: none;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

// Max width container
.max-width-container {
  > .cmp-container:first-of-type {
    max-width: rem(1920);
    margin: auto;
  }
}
