.teaser--small {
  display: flex;
  flex-grow: 1;

  &.teaser--reverse {
    .cmp-teaser__image {
      order: 1;
    }

    .cmp-teaser__content {
      order: 2;
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //max-width: rem(417);
    padding: rem(24) rem(8);
    margin: 0 auto;
    background-color: $primary-color-white;
    border-radius: $border-radius--small;
    box-sizing: border-box;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .cmp-teaser__image {
    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      height: rem(200);
      object-fit: cover;
      border-radius: $border-radius--small;

      @media screen and (min-width: $breakpoint--desktop-large) {
        height: rem(250);
        max-width: 100%;
      }
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    padding: rem(24) rem(16);
    color: $text-color-black;
    gap: 16px;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }

    .cmp-teaser__pretitle {}

    .cmp-teaser__title,
    .cmp-teaser__title a {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: $text-color-black;
      text-transform: none;
      text-decoration: none;
      margin: 0;

      @media screen and (min-width: $breakpoint--tablet-new) {
        font-size: rem(23);
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      display: -webkit-box;
      height: 40px;
      font-size: rem(14);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.07px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint--tablet-new) {
        height: 50px;
        font-size: rem(16);
        letter-spacing: 0.08px;
      }
    }
  }

  .cmp-teaser__action-container {
    @media screen and (min-width: $breakpoint--tablet-new) {
      margin: rem(16) 0 0;
    }
  }

  .cmp-teaser__action-link {
    @extend %cta;

    &:first-child,
    &:nth-child(2) {
      margin-bottom: 0;
      margin-left: 0;
      padding: rem(12) rem(40);
    }
  }
}