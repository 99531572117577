.cmp-button,
.cmp-download__action {
  @extend %cta;
}

.button--primary {
  .cmp-button {
    @extend %cta--primary;
  }
}

.button--secondary {
  .cmp-button {
    @extend %cta--secondary;
  }
}

.button--quiet {
  .cmp-button {
    @extend %cta--quiet;
  }
}

.button--icon-only {
  .cmp-button {
    @extend %cta--icon-only;
  }
}

.button-space--top {
  margin-top: rem(32);

  a, button {
    margin: 0;
  }
}

.button-space--bottom {
  margin-bottom: rem(32);

  a, button {
    margin: 0;
  }
}

.cmp-teaser__action-link--primary {
  @extend %cta--primary;
}

.cmp-teaser__action-link--secondary {
  @extend %cta--secondary;
}

.cmp-teaser__action-link--quiet {
  @extend %cta--quiet;
}

.cmp-teaser__action-link--icon-only {
  @extend %cta--icon-only;
}
