
//== Font loading

@font-face {
    font-family: 'Antarctica';
    src: url('./resources/fonts/Antarctica-Bold.woff2') format('woff2'),
    url('./resources/fonts/Antarctica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica';
    src: url('./resources/fonts/Antarctica-Regular.woff2') format('woff2'),
    url('./resources/fonts/Antarctica-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica';
    src: url('./resources/fonts/Antarctica-Light.woff2') format('woff2'),
    url('./resources/fonts/Antarctica-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica-ExtraLight';
    src: url('./resources/fonts/Antarctica-ExtraLight.woff2') format('woff2'),
    url('./resources/fonts/Antarctica-ExtraLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Antarctica-Black';
    src: url('./resources/fonts/Antarctica-Black.otf') format('otf'),
    url('./resources/fonts/Antarctica-Black.otf') format('otf');
    font-weight: bolder;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica';
    src: url('./resources/fonts/Antarctica-BlackItalic.otf') format('otf'),
    url('./resources/fonts/Antarctica-BlackItalic.otf') format('otf');
    font-weight: bolder;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica';
    src: url('./resources/fonts/Antarctica-BoldItalic.otf') format('otf'),
    url('./resources/fonts/Antarctica-BoldItalic.otf') format('otf');
    font-weight: bolder;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica-LightItalic';
    src: url('./resources/fonts/Antarctica-LightItalic.otf') format('otf'),
    url('./resources/fonts/Antarctica-LightItalic.otf') format('otf');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica';
    src: url('./resources/fonts/Antarctica-Italic.otf') format('otf'),
    url('./resources/fonts/Antarctica-Italic.otf') format('otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica-LightItalic';
    src: url('./resources/fonts/Antarctica-LightItalic.otf') format('otf'),
    url('./resources/fonts/Antarctica-LightItalic.otf') format('otf');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica-SemiBold';
    src: url('./resources/fonts/Antarctica-SemiBold.woff2') format('woff2'),
    url('./resources/fonts/Antarctica-SemiBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antarctica-SemiBoldItalic';
    src: url('./resources/fonts/Antarctica-SemiBoldItalic.otf') format('otf'),
    url('./resources/fonts/Antarctica-SemiBoldItalic.otf') format('otf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
