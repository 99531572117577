.cmp-accordion {
  padding: rem(40) 0;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: $breakpoint--tablet-new) {
    padding: rem(16) 0;
  }
}

.cmp-accordion__item {
  border-bottom: rem(1) $border-colors-dark solid;
}

.cmp-accordion__button {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 80px;
  padding: rem(24) 0 rem(24) 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: $breakpoint--desktop) {
    font-size: rem(23);
    padding: rem(40) rem(24) rem(40) 0;
  }

  .cmp-accordion__icon {
    &:after {
      display: inline-block;
      width: rem(32);
      height: rem(32);
      margin: 0;
      vertical-align: -25%;
      background-color: $icon-dark;
      content: '';
      mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb25zL3BsdXMiPgo8cGF0aCBpZD0iaWNvbiIgZD0iTTIzIDI1SDEzQzEyLjcxNjcgMjUgMTIuNDc5MiAyNC45MDQgMTIuMjg3NSAyNC43MTJDMTIuMDk1OCAyNC41MiAxMiAyNC4yODIyIDEyIDIzLjk5ODVDMTIgMjMuNzE0OCAxMi4wOTU4IDIzLjQ3NzUgMTIuMjg3NSAyMy4yODY1QzEyLjQ3OTIgMjMuMDk1NSAxMi43MTY3IDIzIDEzIDIzSDIzVjEzQzIzIDEyLjcxNjcgMjMuMDk2IDEyLjQ3OTIgMjMuMjg4IDEyLjI4NzVDMjMuNDggMTIuMDk1OCAyMy43MTc4IDEyIDI0LjAwMTUgMTJDMjQuMjg1MiAxMiAyNC41MjI1IDEyLjA5NTggMjQuNzEzNSAxMi4yODc1QzI0LjkwNDUgMTIuNDc5MiAyNSAxMi43MTY3IDI1IDEzVjIzSDM1QzM1LjI4MzMgMjMgMzUuNTIwOCAyMy4wOTYgMzUuNzEyNSAyMy4yODhDMzUuOTA0MiAyMy40OCAzNiAyMy43MTc4IDM2IDI0LjAwMTVDMzYgMjQuMjg1MiAzNS45MDQyIDI0LjUyMjUgMzUuNzEyNSAyNC43MTM1QzM1LjUyMDggMjQuOTA0NSAzNS4yODMzIDI1IDM1IDI1SDI1VjM1QzI1IDM1LjI4MzMgMjQuOTA0IDM1LjUyMDggMjQuNzEyIDM1LjcxMjVDMjQuNTIgMzUuOTA0MiAyNC4yODIyIDM2IDIzLjk5ODUgMzZDMjMuNzE0OCAzNiAyMy40Nzc1IDM1LjkwNDIgMjMuMjg2NSAzNS43MTI1QzIzLjA5NTUgMzUuNTIwOCAyMyAzNS4yODMzIDIzIDM1VjI1WiIgZmlsbD0iIzIxMjEyNCIvPgo8L2c+Cjwvc3ZnPgo=');
      mask-size: cover;

      @media screen and (min-width: $breakpoint--desktop) {
        width: rem(48);
        height: rem(48);
      }
    }
  }

  &:hover {
  }

  /*&:focus:not(:active) {
    box-sizing: border-box;
    border: rem(2) solid $border-colors-dark;
    transition: 0.5s;
    outline: none;
    border-radius: rem(25);
    width: 100%;
    height: 120%;
  }*/
}

.cmp-accordion__button--expanded {
  outline: none;
  border-bottom: none;
  .cmp-accordion__icon {
    &:after {
      mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxMi41QzYuMzU4MzMgMTIuNSA2LjIzOTU4IDEyLjQ1MiA2LjE0Mzc1IDEyLjM1NkM2LjA0NzkyIDEyLjI2IDYgMTIuMTQxMSA2IDExLjk5OTNDNiAxMS44NTc0IDYuMDQ3OTIgMTEuNzM4OCA2LjE0Mzc1IDExLjY0MzNDNi4yMzk1OCAxMS41NDc4IDYuMzU4MzMgMTEuNSA2LjUgMTEuNUgxNy41QzE3LjY0MTcgMTEuNSAxNy43NjA0IDExLjU0OCAxNy44NTYyIDExLjY0NEMxNy45NTIxIDExLjc0IDE4IDExLjg1ODkgMTggMTIuMDAwOEMxOCAxMi4xNDI2IDE3Ljk1MjEgMTIuMjYxMiAxNy44NTYyIDEyLjM1NjdDMTcuNzYwNCAxMi40NTIyIDE3LjY0MTcgMTIuNSAxNy41IDEyLjVINi41WiIgZmlsbD0iIzIxMjEyNCIvPgo8L3N2Zz4K');
      mask-size: cover;
    }
  }
  &:focus {
    //border: 0.125em solid $color-transparent;
    border-radius: 0;
    .cmp-accordion__title {
      color: $color-text;
    }
  }
}

.cmp-accordion__title {
  font-family: $font-family;
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  @media screen and (min-width: $breakpoint--desktop) {
    font-size: rem(23);
  }

}

.cmp-accordion__icon {
  float: right;
}

.cmp-accordion__panel {
  padding: 0 0 rem(40) 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s linear;

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(8) 0 rem(40) 0;
  }
}

.cmp-accordion__panel--expanded {
  max-height: 10000px; // Large enough to accommodate the content
  opacity: 1;

  .container {
    padding: 0;
  }
}

.cmp-accordion__panel--hidden {
  display: block;
  position: absolute;
  left: -9999999px;
}
