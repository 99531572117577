.cmp-navigation {
  font-family: $font-family;
  margin-left: 0;
  margin-right: 0;
  border: none;

  @media screen and (min-width: $breakpoint--desktop) {
    padding: 0 rem(40);
    background-color: $primary-color-white;
    border-radius: rem(25);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.30);
  }
}

.cmp-navigation__group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  min-height: 48px;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: rem(8);

  ._js-open & {
    flex-direction: column;
    margin-top: rem(24);
  }
}

.cmp-navigation__item {
  display: block;
  padding: 13px 16px;
  margin-left: 0;
  border-bottom: 1px solid $table-border-color;

  a {
    font-size: 16px;
    font-style: normal;
    font-weight: 340;
    line-height: 110%;
    letter-spacing: -0.08px;
    padding: rem(12);
    white-space: nowrap;
  }

  &:hover {
    & > .cmp-navigation__item-link {
      color: $secondary-color-blue;
    }
  }

  &:focus {
    border: 0.125em solid transparent;
    border-radius: 0.25em;
    outline: none;

    .cmp-navigation__item-link {
      color: $secondary-color-blue;
    }
  }

  .cmp-navigation__group {
    display: none;
  }

  &.has-submenu {
    > a {
      position: relative;
      padding-right: 30px;

      .submenu-arrow {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: rem(24);
        height: rem(24);
        margin: 0;
        background-image: $icon-chevron-right;
        background-repeat: no-repeat;
        cursor: pointer;

        @media screen and (min-width: $breakpoint--desktop) {
          width: rem(16);
          height: rem(16);
          top: 18px;
          right: 5px;
          background-image: $icon-chevron-down;
        }

      }
    }

    &.opened {
      > a {
        .submenu-arrow {
          @media screen and (max-width: $breakpoint--desktop) {
            top: 11px;
            left: 0;
            right: auto;
            rotate: 180deg;
          }
        }
      }

      .cmp-navigation__group {
        margin: 0;
        padding-top: 0;
        gap: 0;

        @media screen and (min-width: $breakpoint--desktop) {
          padding-top: 24px;
          gap: 4px;
        }

        > .cmp-navigation__item a {
          line-height: 150%;
        }
      }

      @media screen and (max-width: $breakpoint--desktop) {
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 0;
        background: $primary-color-white;
        z-index: 10;

        > a.cmp-navigation__item-link {
          display: block;
          padding: 13px 16px 13px 32px;
          margin-left: 0;
          color: $text-color-black;

          border-bottom: 1px solid #646465;
        }
      }

      @media screen and (min-width: $breakpoint--desktop) {
        > a {
          .submenu-arrow {
            transform: translate(-4px, -10px) rotate(180deg);
          }
        }
      }
    }
  }

  &.has-submenu.opened > .cmp-navigation__group {
    display: block;
    box-sizing: border-box;

    .close-submenu {
      display: none;
    }

    > .cmp-navigation__item.has-submenu {
      display: flex;
      flex-direction: column;

      > a.cmp-navigation__item-link {
        @media screen and (min-width: $breakpoint--desktop) {
          padding: rem(12) 0 0;
        }
      }

      @media screen and (max-width: $breakpoint--desktop) {
        > a {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: inline-block;
            width: rem(11);
            height: rem(6);
            margin: 0;
            background-image: $icon-chevron-down;
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }

        .cmp-navigation__group {
          .cmp-navigation__item {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }

      a {
        padding: rem(8) rem(16);

        @media screen and (min-width: $breakpoint--desktop) {
          padding: 0;
        }
      }
    }

    @media screen and (min-width: $breakpoint--desktop) {
      position: absolute;
      top: calc(100% + 16px);
      left: 0;
      width: 100%;
      display: grid !important;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      gap: 1px;
      min-height: 145px;
      padding: rem(20) 0;
      align-self: stretch;
      border-radius: 8px;
      box-shadow: 0 0 rem(15) 0 rgba(0, 0, 0, 0.10);
      justify-content: center;
      background: $primary-color-white;
      z-index: 10;

      .close-submenu {
        content: '';
        position: absolute;
        top: 15px;
        right: 20px;
        display: inline-block;
        width: rem(24);
        height: rem(24);
        margin: 0;
        background: $icon-close;
        border: 0;
        cursor: pointer;
        pointer-events: all;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        width: 100%;
        height: 20px;
      }
    }
  }

  @media screen and (min-width: $breakpoint--desktop) {
    display: inline-flex;
    padding: 0;
    border: 0;
  }
}

.cmp-navigation__item--active > a {
  color: $secondary-color-blue;
}

.cmp-navigation__item--level-1 {
  .cmp-navigation__group {
    display: flex;
    flex-direction: column;
  }
}

.cmp-navigation__item-link {
  color: $text-color-black;
  display: block;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-decoration: none;

  ._js-open & {
    font-size: rem(14);
    line-height: 150%;
    padding: 0;
  }
}
