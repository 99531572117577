.cmp-image {
  margin-top: 2.25em;
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}

.image--cover {
  .cmp-image {
    position: relative;
    width: 100%; /* Adjust this as needed */
    height: 100%; /* Adjust this as needed */
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
