.caption {
  .cmp-image {
  }
  .cmp-image__link {
  }
  .cmp-image__image {
  }
  .cmp-image__title {
    margin-top: 1rem;
    color: $color-shade-3;
    font-size: 0.625em;
  }
}

.cmp-image__title {
  margin-top: rem(8);
  margin-bottom: rem(16);
  color: $color-text;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 150%; /* 24px */
  letter-spacing: 0.08px;
}

.image {
  position: relative;

  &.image--cover {
    img {
      border-radius: $border-radius--medium;
    }

    .cmp-image__title {
      font-size: rem(16);
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.065px;
      margin: rem(8) 0 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(13);
      }
    }
  }

  &--caption-top {
    .cmp-image__title {
      position: absolute;
      right: 8px;
      bottom: 8px;
      padding: rem(4) rem(16);
      margin: 0;
      font-size: rem(13);
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.065px;
      border-radius: 8px;
      background: $primary-color-white;

      @media screen and (min-width: $breakpoint--desktop) {
        right: 88px;
        bottom: 40px;
      }
    }
  }
}
