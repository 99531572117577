.pc-keynumbers__data,
.teaser--small, .teaser--medium, .keynumberitem, .teaser--big .cmp-teaser__image {
  opacity: 0; // Initially hidden
  transform: translateY(50px); // Move slightly down
  transition: opacity .4s ease-out, transform .4s ease-out; // Smooth transition

  .swiper-wrapper &,
  .aem-AuthorLayer-Edit &,
  .aem-AuthorLayer-Layouting & {
    opacity: 1; // Always visible in authoring mode or swiper
    transform: none; // No movement on scroll
    transition: none; // No transitions
  }
}

.pc-keynumbers__data.in-view,
.teaser--small.in-view, .teaser--medium.in-view, .keynumberitem.in-view, .teaser--big .cmp-teaser__image.in-view {
  opacity: 1; // Fully visible
  transform: translateY(0); // Move to original position
}

// Exclude .teaser--hero from the animations
.teaser--hero, .teaser--lead {
  opacity: 1; // Always visible, no fade effect
  transform: none; // No movement on scroll
  transition: none; // No transitions
}

.teaser--big .cmp-teaser__content,
.teaser--big.teaser--reverse .cmp-teaser__content {
  @media screen and (min-width: $breakpoint--desktop-large) {
    padding-top: rem(100);

    .swiper-wrapper &,
    .aem-AuthorLayer-Edit &,
    .aem-AuthorLayer-Layouting & {
      padding-top: rem(24);
    }
  }
}