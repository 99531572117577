.cmp-form-button {
  background-color: transparent;
  border: 2px solid $primary-color-black;
  border-radius: rem(25);
  color: $primary-color-black;
  margin: 1em 1em 1em 0;
  padding: 12px 40px;
  cursor: pointer;
  font-size: rem(16);

  .dmm-container--background-blue &, .dmm-container--background-dark & {
    border-color: $primary-color-white;
    color: $primary-color-white;
  }
}
