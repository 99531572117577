.teaser--icon {
  &.teaser--reverse {
    .cmp-teaser__image {
      order: 2;
    }

    .cmp-teaser__content {
      order: 1;
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: rem(417);
    padding: rem(40) rem(14);
    margin: 0 auto;
    background-color: $primary-color-white;
    border-radius: rem(25);

    @media screen and (min-width: $breakpoint--tablet-new) {
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
  }

  .cmp-teaser__image {
    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      width: rem(72);
      height: rem(72);
      object-fit: cover;
      border-radius: rem(20);

      @media screen and (min-width: $breakpoint--tablet-new) {
      }
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    padding: rem(24) rem(16);
    margin: 0 auto;
    color: $text-color-black;
    gap: 16px;
    order: 2;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }

    .cmp-teaser__pretitle {}

    .cmp-teaser__title,
    .cmp-teaser__title a {
      font-size: rem(20);
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: $text-color-black;
      text-transform: none;
      text-decoration: none;
      margin: 0;

      @media screen and (min-width: $breakpoint--tablet-new) {
        width: 78%;
        margin: 0 auto;
        font-size: rem(30);
        line-height: 110%;
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      display: -webkit-box;
      height: 50px;
      font-size: rem(16);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.07px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint--tablet-new) {
        height: 100px;
        font-size: rem(23);
        letter-spacing: 0.115px;
      }
    }
  }

  .cmp-teaser__action-container {
   display: none;
  }
}
