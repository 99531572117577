.cmp-download {
  font-family: "Antarctica", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  max-width: rem(313);
  height: fit-content;
  padding: rem(24);
  border-radius: 0.25em;
  background-color: $primary-color-sand;

  @media screen and (min-width: $breakpoint--desktop) {
    max-width: rem(601);
  }
}
.cmp-download__title {
  margin: 0;
}

.cmp-download__title-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  color: $primary-color-black;
  text-decoration: none;

  @media screen and (min-width: $breakpoint--desktop) {
    font-size: 23px;
    font-style: normal;
    font-weight: 340;
    line-height: 130%; /* 29.9px */
  }
}
.cmp-download__description {
  font-size: 14px;
  font-style: normal;
  font-weight: 340;
  line-height: 150%; /* 21px */
  letter-spacing: 0.07px;

  @media screen and (min-width: $breakpoint--desktop) {
    font-size: 16px;
    font-style: normal;
    font-weight: 340;
    line-height: 150%; /* 24px */
    letter-spacing: 0.08px;
  }
}

.cmp-download__properties {
  display: flex;
  margin-top: 0.625rem;
  color: $color-shade-3;
  font-weight: normal;
  font-size: 0.625em;
  flex-wrap: wrap;
  padding-top: rem(16);

  & div {
    float: left;
  }

  @media screen and (min-width: $breakpoint--desktop) {
    padding-top: 0;
  }
}

.cmp-download__property {
  padding:  rem(8) rem(16);
  background-color: $primary-color-white;
  border-radius: rem(24);
  margin-bottom: rem(16);
  margin-top: rem(0);
  font-size: 13px;
  font-style: normal;
  font-weight: 340;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.065px;

  @media screen and (min-width: $breakpoint--desktop) {
    margin-bottom: rem(16);
    margin-top: rem(16);
  }
}

.cmp-download__property-label {
  display: none;
}
.cmp-download__property-content {
  margin: 0 0 0 0;
  word-break: break-word;
}

.cmp-download__property--filename {
  margin-right: rem(8);
}
.cmp-download__property--size {
  order: 2;
}
.cmp-download__property--format {
  order: 1;
  text-transform: uppercase;
  margin-right: rem(8);
}

.cmp-download__action {
  display: block;
  background-color: $secondary-color-blue;
  border-color: $text-color-black;
  border-radius: 25px;
  color: $primary-color-white;
  margin: 0;

  &:hover {
    background-color: $text-color-black;
    & .cmp-button__text {
      color: $primary-color-white;
    }
  }
  &:focus {
    background-color: $text-color-black;
    border-color: $text-color-black;
    outline: none;
    box-shadow: 0 0 0 0.125em $text-color-black;
    & .cmp-button__text {
      color: $primary-color-white;
    }
  }
  &:disabled {
    & .cmp-button__text {
    }
  }
}
.cmp-download__action-text {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: rem(-3);
    right: rem(-30);
    height: rem(24);
    width: rem(24);
    background-image: $icon-white;
  }
}
