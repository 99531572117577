/* headlines */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
  font-weight: normal;
  margin: 0 0 $primitives-size-300;
}

%h1, 
h1 {
    font-size: $font-size-h1-mobile;
    line-height: $line-height-h1-mobile;
    //text-transform: uppercase;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-h1-desktop;
        line-height: $line-height-h1-desktop;   
    }
}

%h2, 
h2 {
    font-size: $font-size-h2-mobile;
    line-height: $line-height-h2-mobile;
    //text-transform: uppercase;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-h2-desktop;
        line-height: $line-height-h2-desktop;   
    }
}

%h3,
h3 {
    font-size: $font-size-h3-mobile;
    line-height: $line-height-h3-mobile;
    //text-transform: uppercase;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-h3-desktop;
        line-height: $line-height-h3-desktop;   
    }
}

%h4,
h4 {
    font-size: $font-size-h4-mobile;
    line-height: $line-height-h4-mobile;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-h4-desktop;
        line-height: $line-height-h4-desktop;
        //text-transform: uppercase;
    }
}

%h5,
h5 {
    font-size: $font-size-h5-mobile;
    line-height: $line-height-h5-mobile;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-h5-desktop;
        line-height: $line-height-h5-desktop;   
    }
}

%h6,
h6 {
    font-size: $font-size-h6-mobile;
    line-height: $line-height-h6-mobile;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-h6-desktop;
        line-height: $line-height-h6-desktop;   
    }
}

._h1 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %h1;
    }
}

._h2 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %h2;
    }
}

._h3 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %h3;
    }
}

._h4 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %h4;
    }
}

._h5 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %h5;
    }
}

._h6 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %h6;
    }
}

p {
    font-size: $font-size-p-medium-mobile;
    line-height: $line-height-p-medium-mobile;
    margin-top: 0;
    @media screen and (min-width: $breakpoint--desktop) {
        font-size: $font-size-p-medium-desktop;
        line-height: $line-height-p-medium-desktop;
    }
}

._p--small {
    p {
        font-size: $font-size-p-small-mobile;
        line-height: $line-height-p-small-mobile;
        @media screen and (min-width: $breakpoint--desktop) {
            font-size: $font-size-p-small-desktop;
            line-height: $line-height-p-small-desktop;
        }
    }
}

._p--large {
    p {
        font-size: $font-size-p-large-mobile;
        line-height: $line-height-p-large-mobile;
        @media screen and (min-width: $breakpoint--desktop) {
            font-size: $font-size-p-large-desktop;
            line-height: $line-height-p-large-desktop;
        }
    }
}

._p--big {
    p {
        font-size: $font-size-p-big-mobile;
        line-height: $line-height-p-big-mobile;
        @media screen and (min-width: $breakpoint--desktop) {
            font-size: $font-size-p-big-desktop;
            line-height: $line-height-p-big-desktop;   
        }
    }
}






