.cmp-form-text {
  display: grid;
  gap: 8px;

  & label {
    font-size: rem(16);
    font-style: normal;
    font-weight: 340;
    line-height: 110%;
    letter-spacing: -0.08px;
    margin-bottom: 0.5625rem;
    color: $text-color-black;
    order: 1;
  }

  &__help-block {
    font-size: rem(13);
    font-weight: 340;
    line-height: 150%;
    letter-spacing: 0.065px;
    order: 3;
  }

  &__textarea {
    height: 10em;
    padding: 0.5625em 1em 0.6875em 1em;
    font-size: 1em;
    background-color: $color-shade-1;
    border: 0.125em solid $color-shade-5;
    border-radius: rem(8);

    &:hover {
      //background-color: $color-background;
    }

    &:focus {
      border-color: $color-accent-lighter;
    }

    &:active {
      color: $color-text;
    }
  }

  &__text {
    min-width: 256px;
    height: 1.25em;
    padding: rem(12) rem(16);
    font-size: rem(16);
    font-style: normal;
    font-weight: 340;
    line-height: 150%;
    letter-spacing: 0.08px;
    background-color: $primary-color-white;
    border-color: $color-shade-5;
    border-style: solid;
    border-width: 1px;
    border-radius: rem(8);
    order: 2;

    &:hover {}

    &:focus {
      box-shadow: 0 0 0 3px $primary-color-white, 0 0 0 5px $primary-color-black, 0 0 0 7px $primary-color-white;
      outline: none;
    }

    &:active {
      color: $color-text;
    }

    &.invalid-input {
      background: $container-error-color;
      color: $main-error-color;
      border-color: $main-error-color;
    }
  }
}
