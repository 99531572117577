.cmp-link__screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
}

%cta {
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;
  margin: 0.5em 0.5em rem(50) 0.5em;
  padding: rem(12) rem(40);
  text-decoration: none;
  font-size: rem(14);
  font-style: normal;
  font-weight: 340;
  line-height: 100%; /* 14px */
  text-transform: uppercase;
  border-radius: rem(25);
  cursor: pointer;

  @media screen and (min-width: $breakpoint--desktop) {
    //font-size: rem(16);
    //padding: rem(16) rem(40);
  }

  .cmp-button__text {
    position: relative;

    /*
    &::before {
      position: absolute;
      content: "";
      top: rem(-3);
      right: rem(-30);
      height: rem(24);
      width: rem(24);
      background-image: $icon-white;

      @media screen and (min-width: $breakpoint--desktop) {
        top: rem(-3.2);
      }
    }
     */
  }
}

%cta--primary {
  border: 1px solid transparent;
  background-color: $secondary-color-blue;
  border-radius: 25px;
  color: $primary-color-white;

  @media (hover: hover) {
    &:active,
    &:hover {
      background-color: $primary-color-black;

      .dmm-container--background-dark &, .teaser--hero & {
        background-color: $primary-color-white;
        color: $text-color-black;

        .cmp-button__text {
          /*
          &::before {
            background-image: $icon-black;
          }
           */
        }
      }
    }
  }

  &:focus {
    border: 1px solid;
    background-color: $primary-color-black;
    outline: none;
    box-shadow: 0 0 0 1px $primary-color-black, inset 0 0 0 1px $primary-color-white;

    .dmm-container--background-dark &, .teaser--hero & {
      background-color: $primary-color-white;
      box-shadow: 0 0 0 1px $primary-color-white, inset 0 0 0 2px $primary-color-black;
      color: $text-color-black;

      .cmp-button__text {
        color: $text-color-black;

        /*
        &::before {
          background-image: $icon-black;
        }
         */
      }
    }
  }
}

%cta--secondary {
  border: 1px solid $primary-color-black;
  background-color: transparent;
  color: $text-color-black;

  .cmp-button__text {
    /*
    &::before {
      background-image: $icon-black;

      .dmm-container--background-dark &,
      .dmm-container--background-blue & {
        background-image: $icon-white;
      }
    }
     */
  }

  .dmm-container--background-dark &,
  .dmm-container--background-blue & {
    border-color: $primary-color-white;
    color: $primary-color-white;
  }

  @media (hover: hover) {
    &:active,
    &:hover {
      border: 1px solid $secondary-color-blue;
      color: $secondary-color-blue;

      & .cmp-button__text {
        color: $secondary-color-blue;
      }

      .dmm-container--background-dark &,
      .dmm-container--background-blue & {
        background-color: $primary-color-white;
        border-color: $primary-color-white;

        .cmp-button__text {
          color: $text-color-black;

          /*
          &::before {
            background-image: $icon-black;
          }
           */
        }
      }
    }
  }

  &:focus {
    border: 1px solid $secondary-color-blue;
    background-color: $primary-color-white;
    outline: none;
    box-shadow: 0 0 0 3px $primary-color-white, 0 0 0 5px $primary-color-black, 0 0 0 7px $primary-color-white;

    & .cmp-button__text {
      color: $secondary-color-blue;
    }

    .dmm-container--background-dark &,
    .dmm-container--background-blue & {
      .cmp-button__text {
        color: $text-color-black;

        /*
        &::before {
          background-image: $icon-black;
        }
         */
      }
    }
  }

  // edge case for the hero-teaser 
  @media screen and (min-width: $breakpoint--desktop) {
    .teaser--hero & {
      border-color: $primary-color-white;
      color: $primary-color-white;

      &:focus {
        color: $secondary-color-blue;
      }

      @media (hover: hover) {
        &:active,
        &:hover {
          border: 1px solid $primary-color-white;

          background-color: $primary-color-white;
          color: $primary-color-black;
        }
      }
    }
  }
}

%cta--quiet {
  background-color: transparent;
  color: $text-color-black;
  padding: rem(8);
  border-radius: rem(8);

  .cmp-button__text {
    /*
    &::before {
      background-image: $icon-quit-black;

      .dmm-container--background-dark &,
      .dmm-container--background-blue & {
        background-image: $icon-quit-white;
      }
    }
     */
  }

  .dmm-container--background-dark &,
  .dmm-container--background-blue & {
    color: $primary-color-white;
  }

  @media (hover: hover) {
    &:active,
    &:hover {
      color: $secondary-color-blue;

      & .cmp-button__text {
        color: $secondary-color-blue;
      }

      .dmm-container--background-dark &,
      .dmm-container--background-blue & {
        background-color: $primary-color-white;
        border-color: $primary-color-white;

        color: $text-color-black;

        .cmp-button__text {
          color: $text-color-black;

          /*
          &::before {
            background-image: $icon-quit-black;
          }
           */
        }
      }
    }


    &:hover {
      border: 0;

      & .cmp-button__text {
        color: $secondary-color-blue;

        /*
        &::before {
          background-image: $icon-quit-blue;
        }
         */
      }
    }
  }

  &:active {
    color: $primary-color-white;
    background: $secondary-color-blue;

    & .cmp-button__text {
      color: $primary-color-white;

      /*
      &::before {
        background-image: $icon-quit-white;
      }
       */
    }
  }

  &:focus {
    background-color: $primary-color-white;
    color: $primary-color-black;
    box-shadow: 0 0 0 1px $primary-color-white, inset 0 0 0 2px $primary-color-black;

    .cmp-button__text {
      /*
      &::before {
        background-image: $icon-quit-black;
      }
       */
    }
  }
}

%cta--icon-only {
  background-color: $secondary-color-blue;
  border-radius: 50%;
  padding: rem(14);
  height: rem(34);
  width: rem(34);
  border: 1px solid transparent;

  .dmm-container--background-blue & {
    background-color: $primary-color-white;
  }

  .cmp-button__text {
    display: none;
  }

  /*
  &::before {
    position: absolute;
    content: "";
    top: rem(18);
    right: rem(18);
    height: rem(24);
    width: rem(24);
    background-image: $icon-white;

    .dmm-container--background-blue & {
      background-image: $icon-black;
    }
  }
   */

  @media (hover: hover) {
    &:active,
    &:hover {
      background-color: $primary-color-black;

      & .cmp-button__text {
        color: $primary-color-white;
      }

      /*
      &::before {
        .dmm-container--background-blue & {
          background-image: $icon-white;
        }
      }
       */
    }
  }

  &:focus {
    background-color: $primary-color-black;
    outline: none;
    box-shadow: 0 0 0 1px $primary-color-black, inset 0 0 0 2px $primary-color-white;

    & .cmp-button__text {
      color: $primary-color-white;
    }

    /*
    &::before {
      .dmm-container--background-blue & {
        background-image: $icon-white;
      }
    }
     */
  }
}
