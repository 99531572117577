.dmm-container--full {
  @extend .max-width-container;

  padding: rem(40) rem(14);

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(80) rem(14);
  }
}

.dmm-container--full-eighty {
  @extend .max-width-container;

  padding: rem(40) rem(14);

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(80) rem(88);
  }
}

.dmm-container--legal {
  @extend .max-width-container;

  padding: rem(100) rem(14) rem(40) rem(14);

  .cmp-title {
    margin-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: $breakpoint--desktop) {
      padding-bottom: rem(20);
    }
  }

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(160) rem(88) rem(80) rem(88);
  }
}

.dmm-container--full-large {
  @extend .max-width-container;

  padding: rem(24) rem(14);

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(64) rem(88);
  }
}

.dmm-container--fifty-fifty {
  @extend .max-width-container;

  > .cmp-container:first-of-type {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(8);

    > *:nth-child(1) {
      display: flex;
      grid-column: span 12;
    }

    > *:nth-child(2) {
      display: flex;
      grid-column: span 12;
    }

    @media screen and (min-width: $breakpoint--tablet-new) {

      > *:nth-child(1) {
        grid-column: span 6;
      }

      > *:nth-child(2) {
        grid-column: span 6;
      }
    }

    @media screen and (min-width: $breakpoint--desktop) {

      > *:nth-child(1) {
        grid-column: span 6;
      }

      > *:nth-child(2) {
        grid-column: span 6;
      }
    }
  }
}

.dmm-container--one-third {
  @extend .max-width-container;

  > .cmp-container:first-of-type {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(8);

    > *:nth-child(n) {
      grid-column: span 12;
    }

    @media screen and (min-width: $breakpoint--tablet-new) {
      > *:nth-child(n) {
        grid-column: span 4;
      }
    }

    @media screen and (min-width: $breakpoint--desktop) {
      > *:nth-child(n) {
        grid-column: span 4;
      }
    }
  }
}

.dmm-container--one-fourth {
  @extend .max-width-container;

  > .cmp-container:first-of-type {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(8);

    > *:nth-child(n) {
      grid-column: span 12;
    }

    @media screen and (min-width: $breakpoint--desktop) {
      > *:nth-child(n) {
        grid-column: span 3;
      }
    }

    @media screen and (min-width: $breakpoint--desktop-large) {
      > *:nth-child(n) {
        grid-column: span 3;
      }
    }
  }
}

.dmm-container--seventy-thirty-percent {
  @extend .max-width-container;

  > .cmp-container:first-of-type {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(8);

    > *:nth-child(1) {
      grid-column: span 12;
    }

    > *:nth-child(2) {
      grid-column: span 12;
    }

    @media screen and (min-width: $breakpoint--tablet-new) {
      > *:nth-child(1) {
        grid-column: span 8;
      }

      > *:nth-child(2) {
        grid-column: 10 / span 3;
      }
    }

    @media screen and (min-width: $breakpoint--desktop-large) {
      > *:nth-child(1) {
        grid-column: span 8;
      }

      > *:nth-child(2) {
        grid-column: 10 / span 3;
      }
    }

    .cmp-image {
      margin-top: 0;
    }
  }
}

.dmm-container--thirty-seventy-percent {
  @extend .max-width-container;

  > .cmp-container:first-of-type {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(8);

    > *:nth-child(1) {
      grid-column: span 12;
    }

    > *:nth-child(2) {
      grid-column: span 12;
    }

    @media screen and (min-width: $breakpoint--tablet-new) {
      > *:nth-child(1) {
        grid-column: span 6;
      }

      > *:nth-child(2) {
        grid-column: span 6;
      }
    }

    @media screen and (min-width: $breakpoint--desktop) {
      > *:nth-child(1) {
        grid-column: span 3;
      }

      > *:nth-child(2) {
        grid-column: 5 / span 8;
      }
    }
  }
}

.dmm-container--quote {
  @extend .max-width-container;

  padding: rem(120) rem(14) rem(120) rem(14);

  h2.cmp-title__text {
    font-size: rem(40);
    font-style: normal;
    font-weight: 400;
    line-height: rem(50);
  }

  .text .cmp-text p {
    font-size: 24px;
    font-style: normal;
    font-weight: 340;
    line-height: 150%; /* 36px */
    letter-spacing: 0.12px;
  }

  .separator {
    margin-top: rem(40);
    margin-bottom: rem(16);
    border-bottom: 1px solid $primary-color-black;
  }

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(120) rem(88) rem(120) rem(88);

    h2.cmp-title__text {
      font-size: rem(70);
      line-height: rem(86);
    }

    .text .cmp-text p {
      font-size: rem(34);
      letter-spacing: 0.17px;
    }
  }
}

.dmm-container--with-title-top {
  @extend .max-width-container;

  padding: rem(40) rem(8) 0 rem(8);

  @media screen and (min-width: $breakpoint--desktop) {
    padding: rem(80) rem(88) 0 rem(88);
  }
}

.dmm-container--no-padding-top {
  @extend .max-width-container;

  padding: 0 rem(8) 0 rem(8);

  @media screen and (min-width: $breakpoint--desktop) {
    padding: 0 rem(88) 0 rem(88);
  }
}

.dmm-container--with-benefits {
  &-left,
  &-right {
    @extend .max-width-container;

    float: none !important;
    margin: 0 auto;
    padding: rem(40) rem(8);
    overflow: hidden;

    @media screen and (min-width: $breakpoint--desktop) {
      padding: rem(80) rem(88);
    }

    .cmp-title {
      margin: 0;

      .cmp-title__text {
        font-size: rem(30);

        @media screen and (min-width: $breakpoint--desktop) {
          font-size: rem(50);
        }
      }
    }

    .cmp-text,
    .cmp-text p {
      margin: 0;
      font-size: rem(24);
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.12px;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(34);
        letter-spacing: 0.17px;
      }
    }

    .button {
      .cmp-button {
        margin: 0;
      }
    }
  }

  &-left {
    padding-bottom: rem(40);

    @media screen and (min-width: $breakpoint--desktop) {
      padding-bottom: rem(24);
    }

    .cmp-container {
      display: flex;
      flex-direction: column;
      gap: rem(24);
      max-width: rem(880);
    }
  }

  &-right {
    padding-top: 0;
    padding-bottom: rem(80);

    @media screen and (min-width: $breakpoint--desktop) {
      padding-bottom: rem(40);
    }

    .cmp-container {
      display: flex;
      justify-content: flex-end;
    }

    .swiper-container {
      position: relative;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      width: rem(44);
      height: rem(26.4);
      margin: 0.125em;
      background-color: transparent;
      border: 1px solid $primary-color-black;
      border-radius: rem(25);
      color: $primary-color-black;
      top: auto;
      bottom: rem(-30);
      z-index: 10;
      cursor: pointer;
      box-sizing: border-box;

      &:after {
        font-size: rem(14);
      }
    }

    .swiper-button-prev {
      right: rem(62);
      left: auto;
    }

    .swiper-button-next {
      right: 0;
    }

  }
}

.dmm-container--more-padding {
  padding-bottom: rem(120);
  padding-top: rem(120);
}

// backgrounds
.dmm-container--background-dark,
.dmm-container--background-dark-green,
.dmm-container--background-blue {
  background-color: $primary-color-softgrey;
  color: $primary-color-white;

  .cmp-container {
    .text, .cmp-text {
      color: $primary-color-white;
    }
  }
  .cmp-form {
    .text, .cmp-text, .cmp-form-options__field-description {
      color: $primary-color-white;
    }
  }

  .cmp-image {
    margin-top: rem(10);
  }

  .cmp-form-options__field-description {
    color: $primary-color-white;
  }

  .cmp-form-options__field--checkbox {
    &:checked {
      &::before {
        background-color: $primary-color-white;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMTM0MTUgMTIuODE1NUwxNS4xNzU3IDUuNzczOTRDMTUuMjUzNiA1LjY5NjA3IDE1LjM0NTQgNS42NTQ1NCAxNS40NTExIDUuNjQ5MzRDMTUuNTU2NyA1LjY0NDI3IDE1LjY1MzYgNS42ODU4MSAxNS43NDE3IDUuNzczOTRDMTUuODMgNS44NjIyMSAxNS44NzQxIDUuOTU3MzQgMTUuODc0MSA2LjA1OTM0QzE1Ljg3NDEgNi4xNjE0NyAxNS44MyA2LjI1NjYxIDE1Ljc0MTcgNi4zNDQ3NEw4LjU4NjU1IDEzLjUwNDdDOC40NTcyMSAxMy42MzM5IDguMzA2NDEgMTMuNjk4NSA4LjEzNDE1IDEzLjY5ODVDNy45NjE4OCAxMy42OTg1IDcuODExMDggMTMuNjMzOSA3LjY4MTc1IDEzLjUwNDdMNC40NDE3NSAxMC4yNjQ3QzQuMzYzODggMTAuMTg2OSA0LjMyMzE1IDEwLjA5NDMgNC4zMTk1NSA5Ljk4NzE0QzQuMzE1OTUgOS44Nzk5NCA0LjM1ODI4IDkuNzgyMjEgNC40NDY1NSA5LjY5Mzk0QzQuNTM0NjggOS42MDU4MSA0LjYyOTc1IDkuNTYxNzQgNC43MzE3NSA5LjU2MTc0QzQuODMzODggOS41NjE3NCA0LjkyOTAxIDkuNjA1ODEgNS4wMTcxNSA5LjY5Mzk0TDguMTM0MTUgMTIuODE1NVoiIGZpbGw9IiMyMTIxMjQiLz4KPC9zdmc+Cg==");
      }
    }
  }

  .cmp-form-options__field--radio {
    &:checked {
      &::before {
        background-color: $primary-color-white;
      }
    }
  }

  input {
    border-color: $primary-color-white;
    background-color: $primary-color-softgrey;
    color: $primary-color-white;
  }

  .cmp-form-text label {
    color: $primary-color-white;
  }

  .cmp-image__image {
    border-radius: rem(8);
  }

  .select-selected {
    color: $primary-color-white;
    border: 1px solid $primary-color-white;

    &:after {
      background-color: $text-color-black;
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTRsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE1TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==');
    }

    &:hover {
      border-color: $primary-color-white;
    }

    &:focus {
      box-shadow: 0 0 0 3px $primary-color-white, 0 0 0 5px $primary-color-black, 0 0 0 7px $primary-color-white;
      outline: none;
    }
  }

  .select-items {
    border: 1px solid $primary-color-white;
    background: $primary-color-white;
  }

  .teaser {
    &--big,
    &--hero,
    &--hero-slim,
    &--small-round {
      .cmp-teaser__title,
      .cmp-teaser__title a,
      .cmp-teaser__description,
      .cmp-teaser__description p {
        color: $primary-color-white;
      }
    }

    .cmp-teaser__action-link {
      &:hover,
      &:focus {
        color: $text-color-black;
      }
    }
  }
}

.dmm-container--background-dark {
  .separator {
    border-bottom: 1px solid $primary-color-white;
  }
}

.dmm-container--background-blue {
  background-color: $secondary-color-blue;

  input {
    background-color: $secondary-color-blue;
  }

  .separator {
    border-bottom: 1px solid $primary-color-white;
  }
}

.dmm-container--background-sand {
  background-color: $primary-color-sand;
  color: $primary-color-black;

  .cmp-form {
    .text, .cmp-text, .cmp-form-options__field-description {
      color: $primary-color-black;
    }
  }

  .cmp-image {
    margin-top: rem(10);
  }

  input {
    border-color: $primary-color-black;
    background-color: $primary-color-sand;;
    color: $primary-color-black;
  }

  .cmp-form-text label {
    color: $primary-color-black;
  }

  .cmp-image__image {
    border-radius: rem(8);
  }

  .separator {
    border-bottom: 1px solid $primary-color-black;
  }
}
