.cmp-text {
  margin: 0.75rem 0 0 0;
  color: $color-text;
  font-weight: normal;

  @extend p;

  & b {
    font-weight: bold;
  }

  & p a {
    color: $secondary-color-blue;
    text-decoration: none;
    border: 0.125em solid $color-transparent;
    border-radius: 0.25em;

    &:hover {
      color: $secondary-color-blue;
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $secondary-color-blue;
      text-decoration-style: solid;
    }

    &:focus {
      text-decoration: underline;
      border-color: $secondary-color-blue;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $secondary-color-blue;
      text-decoration-style: solid;
    }
  }

  & blockquote {
    margin: 0.75rem 0;
    font-size: 1.75em;
    font-family: titleFont, serif;
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }
}

.cmp-text__paragraph {
}
