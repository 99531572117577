.cmp-tabs {
  @media screen and (min-width: $breakpoint--desktop) {
  .cmp-tabs--vertical & {
    display: flex;
    }
  }

  .container {
    padding-top: rem(10);

    @media screen and (min-width: $breakpoint--desktop) {
      padding-top: rem(20);

      .cmp-tabs--vertical & {
        padding-top: rem(0);
        padding-left: rem(20);
      }
    }
  }
}

.cmp-tabs__tablist {
  flex-wrap: nowrap;
  overflow-x: scroll;

  @media screen and (min-width: $breakpoint--desktop) {
    flex-wrap: wrap;
    overflow: visible;
    display: flex;
    flex-direction: row;

    .cmp-tabs--vertical & {
      display: flex;
      flex-direction: column;
    }
  }
}

.cmp-tabs__tab {
  font-family: $font-family;
  font-size: rem(16);
  font-style: normal;
  font-weight: 340;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.08px;
  border-bottom: 1px solid $primary-color-black;
  padding: rem(24) rem(32);
  min-width: rem(224);
  justify-content: center;
  align-items: center;
  display: flex;

  .cmp-tabs--vertical & {
    @media screen and (min-width: $breakpoint--desktop) {
      border-bottom: none;
      border-right: 1px solid $primary-color-black;
    }
  }

  &:hover {
    border-bottom: 4px solid $secondary-color-blue;
    padding: rem(24) rem(32) rem(21) rem(32);

    .cmp-tabs--vertical & {
      @media screen and (min-width: $breakpoint--desktop) {
        border-bottom: none;
        border-right: 4px solid $secondary-color-blue;
        padding: rem(24) rem(29) rem(24) rem(32);
      }
    }
  }
}
.cmp-tabs__tab--active {
  border-bottom: 4px solid $secondary-color-blue;
  padding: rem(24) rem(32) rem(21) rem(32);

  &:focus {
    border-bottom: 4px solid $secondary-color-blue;

    .cmp-tabs--vertical & {
      @media screen and (min-width: $breakpoint--desktop) {
        padding: rem(24) rem(29) rem(24) rem(32);
        border-bottom: none;
        border-right: 4px solid $secondary-color-blue;
      }
    }
  }
  .cmp-tabs--vertical & {
    @media screen and (min-width: $breakpoint--desktop) {
      border-bottom: none;
      padding: rem(24) rem(29) rem(24) rem(32);
      border-right: 4px solid $secondary-color-blue;
    }
  }
}

.cmp-tabs__tabpanel--active {
  padding-right: 0.5em;
  padding-left: 0.5em;
}


