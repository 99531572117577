.search {
  justify-content: center;
  margin-top: rem(30);
  display: none;

  @media screen and (min-width: $breakpoint--desktop-large) {
    margin: 0;
  }

  .close-search {
    display: none;

    @media screen and (min-width: $breakpoint--desktop-large) {
      content: '';
      position: absolute;
      top: 15px;
      right: 20px;
      display: inline-block;
      width: rem(24);
      height: rem(24);
      margin: 0;
      background: $icon-close;
      border: 0;
      cursor: pointer;
      pointer-events: all;
    }
  }
}

.search._js-show {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: $breakpoint--desktop-large) {
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    width: 100%;
    justify-items: center;
    gap: 1px;
    min-height: 136px;
    padding: 0;
    margin: 0;
    align-self: stretch;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 rem(15) 0 rgba(0, 0, 0, 0.10);
    justify-content: center;
    background: $primary-color-white;
    z-index: 10;
  }
}

.cmp-experiencefragment--header.submenu-hide-breadcrumb {
  .search._js-show {
    top: calc(100% - 36px);
  }
}

.cmp-search {
  width: 100%;

  @media screen and (min-width: $breakpoint--desktop-large) {
    width: rem(678);
  }
}

.cmp_search__info {
  height: 0;
  color: transparent;
}

.cmp-search__field {
  width: calc(-75px + 100%);
  height: auto;
}

.cmp-search__icon {
  width: rem(24);
  height: rem(24);
  top: rem(15);
  left: rem(18);
  background-image: $icon-search;
}

.cmp-search__loading-indicator {
  top: rem(17);
  left: rem(20);
  background: $primary-color-white;
}

.cmp-search__input {
  padding: rem(18) rem(24) rem(18) rem(48);
  border-radius: rem(8);
  border: 1px solid black;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.08px;
  outline: none;

  @media screen and (min-width: $breakpoint--desktop-large) {

  }
}

.cmp-search__loading-indicator {}
.cmp-search__clear {}

.cmp-search__clear-icon {
  width: rem(24);
  height: rem(24);
  top: rem(17);
  right: rem(-48);
  background-image: $icon-close;
}

.cmp-search__results {
  padding: rem(12) rem(24);
  width: calc(-51px + 100%);
  min-width: 0;
  top: 43px;
  left: 0;
  border-radius: 0 0 8px 8px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-top: none;

  @media screen and (min-width: $breakpoint--desktop-large) {
    padding: rem(12) rem(24) rem(24);
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cmp-search__item {
  margin-bottom: rem(16);
}

.cmp-search__item:first-of-type::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  margin-bottom: rem(24);

  @media screen and (min-width: $breakpoint--desktop-large) {
    margin-bottom: rem(40);
  }
}

.cmp-search__item--is-focused {}

.cmp-search__item-mark {
  background-color: $text-color-mark;
}

.cmp-search__item-title {
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.08px;
  color: $text-color-black;
}