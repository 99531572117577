.keynumberitem {
  box-sizing: border-box;
}

.pc-keynumbers {
  display: flex;
  flex-direction: column;
  padding-top: rem(80);
  padding-bottom: rem(80);

  @media screen and (min-width: $breakpoint--tablet-new) {
    flex-direction: row;
  }
}

.pc-keynumbers__data {
  width: 100%;
  padding-top: rem(40);
  padding-left: rem(14);
  box-sizing: border-box;

  @media screen and (min-width: $breakpoint--desktop-large) {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    padding-left: rem(88);
    flex-direction: column;
    padding-top: 0;

    &-wrapper {
      max-width: 80%;
    }
  }
}

.pc-keynumbers__title {
  font-family: $font-family;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  @media screen and (min-width: $breakpoint--desktop-large) {
    font-size: 54px;
  }
}

.pc-keynumbers__description {
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 340;
  line-height: 150%; /* 30px */
  letter-spacing: 0.1px;

  @media screen and (min-width: $breakpoint--desktop-large) {
    font-size: 30px;
  }
}

.pc-keynumbers__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: rem(14);
  padding-left: rem(14);
  box-sizing: border-box;

  @media screen and (min-width: $breakpoint--desktop-large) {
    width: 50%;
    padding-right: rem(80);
  }
}

.pc-key-numbers__content {
  padding: rem(40) rem(0);
  border-bottom: 1px solid $text-color-black;
  display: flex;
  flex-direction: column;

  .dmm-container--background-dark &,
  .dmm-container--background-dark-green &,
  .dmm-container--background-blue & {
    border-bottom: 1px solid $primary-color-white;
  }

  @media screen and (min-width: $breakpoint--desktop-large) {
    padding-left: 0;
    padding-right: 0;
    font-size: 110px;
    flex-direction: row;
  }

  .pc-key-numbers__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 30%;
    padding-left: rem(14);

    @media screen and (min-width: $breakpoint--desktop-large) {
      align-self: center;
      padding-left: 0;
    }
  }

  .pc-key-numbers__number {
    font-family: $font-family-light;
    font-size: 80px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;

    .dmm-container--background-dark-green & {
      color: $secondary-color-green;
    }

    @media screen and (min-width: $breakpoint--desktop-large) {
      font-size: 110px;
      width: 70%;

    }
  }

  .pc-key-numbers__unit {
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */

    @media screen and (min-width: $breakpoint--desktop-large) {
      font-size: 23px;
    }
  }

  .pc-key-numbers__content-wrapper p:last-of-type {
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 340;
    line-height: 150%; /* 21px */
    letter-spacing: 0.07px;

    @media screen and (min-width: $breakpoint--desktop-large) {
      font-size: 16px;
    }
  }
}