.teaser--big {
  &.teaser--reverse {
    .cmp-teaser__image {
      order: 2;

      & .cmp-image {
        margin: 0 0 0 8px;

        @media screen and (min-width: $breakpoint--desktop) {
          margin: 0;
        }
      }

      & img {
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .cmp-teaser__content {
      @media screen and (min-width: $breakpoint--desktop) {
        width: 87.5%;
        padding: 24px 0 24px 88px;
        order: 1;
      }
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    padding-top: rem(40);
    padding-bottom: rem(40);
    gap: 24px;

    @media screen and (min-width: $breakpoint--desktop) {
      padding-top: rem(63);
      padding-bottom: rem(63);
      flex-direction: row;
      gap: 32px;
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
  }

  .cmp-teaser__image {
    width: 100%;
    order: 1;

    @media screen and (min-width: $breakpoint--desktop) {
      width: 100%;
    }

    & .cmp-image {
      margin: 0 8px 0 0;

      @media screen and (min-width: $breakpoint--desktop) {
        margin: 0;
      }
    }

    & img {
      display: block;
      height: 15em;
      object-fit: cover;
      border-radius: rem(20);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      @media screen and (min-width: $breakpoint--desktop-large) {
        height: 28.75em;
      }
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 14px;

    @media screen and (min-width: $breakpoint--desktop) {
      width: 87.5%;
      padding: 24px 88px 24px 0px;
      gap: 32px;
      order: 2;
    }

    .cmp-teaser__pretitle {}

    .cmp-teaser__title,
    .cmp-teaser__title a {
      max-width: 97.5%;
      font-size: rem(30);
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      color: $text-color-black;
      text-decoration: none;
      margin: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(50);
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      font-size: rem(16);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.08px;
      margin: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(23);
        letter-spacing: 0.115px;
      }
    }
  }

  .cmp-teaser__action-link {
    @extend %cta;

    &:first-child,
    &:nth-child(2) {
      margin: 0 0 rem(8) 0;
      padding: rem(12) rem(40);
    }
  }
}