.table {
  overflow-x: auto;

  @media screen and (min-width: $breakpoint--desktop) {
    max-width: fit-content;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    border: none;
    overflow: hidden;

    tr, td, th {
      border: none;
    }

    td {
      width: auto;
      padding: 12px 16px;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.08px;
      color: $text-color-black;
      border-bottom: 1px solid $table-border-color;
    }

    tbody {
      tr:first-child {
        background-color: $primary-color-lightgrey;
      }
    }

    tr:not(:first-child) {
      td:first-child {
        font-weight: bold;
      }
    }

    tr {
      td:not(:last-child) {
        border-right: 1px solid $table-border-color;
      }
    }
  }
}