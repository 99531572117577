.dmm-contact {
    .title {
        display: block!important;
        margin: 1em;
    }

    .cmp-text:first-child {        
        color: #fff;        
        margin: 1em;
    }

    .cmp-text p {
        font-size: 30px;
    }
}
