.cmp-experiencefragment--footer {
  background-color: $primary-color-softgrey;
  width: 100%;
  padding-top: rem(44);

  @media screen and (min-width: $breakpoint--desktop) {
    padding-top: rem(64);
  }

  > .cmp-container .container > .cmp-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(10);
    column-gap: rem(24);
  }

  > .cmp-container .container > .cmp-container {
    .image {
      grid-column: 1 / span 12;
      width: rem(148);
      height: rem(14);
      padding: 0 rem(16) rem(50);

      .cmp-image {
        margin-top: 0;
      }

      @media screen and (min-width: $breakpoint--desktop) {
        grid-column: 1 / span 3;
        padding: rem(6) rem(88);
      }
    }

    .container:nth-child(2) {
      grid-column: 1 / span 6;
      padding-bottom: rem(47);
      padding-left: rem(16);

      @media screen and (min-width: $breakpoint--desktop) {
        grid-column: 4 / span 3;
        padding-left: 0;
      }

      .cmp-container {
        display: flex;
        flex-direction: column;
        gap: rem(24);
      }

      .list,
      .text {
        ul,
        .cmp-text {
          display: flex;
          flex-direction: column;
          gap: rem(16);

          p {
            margin: 0;
            line-height: 110%;

            a {
              border: 0;
            }
          }
        }
      }

      a {
        color: $primary-color-white;
      }
    }

    .container:nth-child(3) {
      grid-column: 7 / span 5;
      padding-bottom: rem(47);

      @media screen and (min-width: $breakpoint--desktop) {
        grid-column: 7 / span 3;
      }

      .cmp-container {
        display: flex;
        flex-direction: column;
        gap: rem(24);
      }

      .list {
        ul {
          display: flex;
          flex-direction: column;
          gap: rem(16);
        }
      }

      a {
        color: $primary-color-white;
      }
    }

    .container:nth-child(4) {
      grid-column: 1 / span 6;
      padding-bottom: rem(47);
      padding-left: rem(16);

      @media screen and (min-width: $breakpoint--desktop) {
        grid-column: 10 / span 3;
      }

      .cmp-container {
        display: flex;
        flex-direction: column;
        gap: rem(24);
      }

      .list {
        ul {
          display: flex;
          flex-direction: column;
          gap: rem(16);
        }
      }

      a {
        color: $primary-color-white;
      }
    }

    .container:last-of-type {
      background-color: $primary-color-white;
      color: $text-color-black;
      grid-column: 1 / span 12;

      .list, .text {
        grid-column: 1 / span 12;
      }

      ul, .cmp-text {
        display: flex;
        flex-wrap: wrap;
        padding: rem(14) rem(16);
        gap: rem(16);

        @media screen and (min-width: $breakpoint--desktop) {
          padding: rem(40) rem(88);
          justify-content: space-between;
          gap: rem(40);
        }
      }

      li, p {
        margin: 0;
      }

      a {
        color: $text-color-black;
        font-size: rem(14);
        font-style: normal;
        font-weight: 340;
        line-height: 110%;

        @media screen and (min-width: $breakpoint--desktop) {
          line-height: 160%;
        }
      }
    }
  }

  .cmp-title {
    color: $primary-color-white;
    margin: 0;
    padding: 0;

    .cmp-title__text {
      font-family: $font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(23);
      }
    }
  }

  .list, .text {
    ul, .cmp-text, .cmp-text a {
      font-family: $font-family;
      font-size: rem(14);
      font-style: normal;
      font-weight: 340;
      line-height: 110%;
      list-style: none;
      margin: 0;
      padding: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        font-size: rem(16);
        line-height: 150%;
      }
    }

    a {
      text-decoration: none;

      &:hover {
          text-decoration: underline;
      }
    }
  }
}