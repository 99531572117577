.teaser--reading-time {
  &.teaser--reverse {
    .cmp-teaser__image {
      order: 1;

      & img {
        border-top-left-radius: rem(20);
        border-top-right-radius: rem(20);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        @media screen and (min-width: $breakpoint--tablet-new) {
        }
      }
    }

    .cmp-teaser__content {
      order: 2;
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    //max-width: rem(433);
    margin: 0 auto;
    background-color: $primary-color-white;
    border-radius: rem(25);
    height: 100%;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .cmp-teaser__image {
    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      height: rem(200);
      object-fit: cover;
      border-radius: rem(20);
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      @media screen and (min-width: $breakpoint--desktop) {
        height: rem(280);
      }
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    padding: rem(48) rem(16) rem(24);
    margin: 0 auto;
    color: $text-color-black;
    gap: 16px;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }

    .cmp-teaser__pretitle {
      position: relative;
      margin: rem(23) 0 0;
      line-height: 110%;
      letter-spacing: -0.08px;
      order: 4;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: rem(24);
        height: rem(24);
        margin: 0;
        background-image: $icon-arrow-right;
        background-repeat: no-repeat;
      }
    }

    .cmp-teaser__title,
    .cmp-teaser__title a {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: $text-color-black;
      text-transform: none;
      text-decoration: none;
      margin: 0;
      order: 1;

      @media screen and (min-width: $breakpoint--tablet-new) {
        font-size: rem(23);
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      display: -webkit-box;
      height: 40px;
      font-size: rem(14);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.07px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      order: 2;

      @media screen and (min-width: $breakpoint--tablet-new) {
        height: 50px;
        font-size: rem(16);
        letter-spacing: 0.08px;
      }
    }
  }

  .cmp-teaser__action-container {
    display: none;
  }
}