.carousel {
  margin-bottom: rem(40);
}

.cmp-carousel {
  margin: 0.5em;
}

.cmp-carousel__content {
  position: relative;
}

.cmp-carousel__item {
}

.cmp-carousel__actions {
  position: absolute;
  right: 0.5em;
  bottom: -1em;
  display: flex;
  flex-direction: row;
  float: right;
  gap: 16px;

  @media screen and (min-width: $breakpoint--desktop) {
    bottom: -2.25em;
    right: rem(40);
  }
}

.cmp-carousel__action {
  position: relative;
  width: rem(44);
  height: rem(26.4);
  margin: 0.125em;
  background-color: transparent;
  border: 1px solid $primary-color-black;
  border-radius: 25px;
  box-shadow: none;
  cursor: pointer;

  .dmm-container--background-dark &,
  .dmm-container--background-blue & {
    border: 1px solid $primary-color-white;
  }

  @media screen and (min-width: $breakpoint--desktop-large) {
    width: rem(80);
    height: rem(48);
  }

  & .cmp-carousel__action-icon {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background-color: $primary-color-black;
      transform: translate(-50%, -50%);
      content: '';
      mask-size: cover;

      @media screen and (min-width: $breakpoint--desktop-large) {
        width: rem(48);
        height: rem(48);
      }

      .dmm-container--background-dark &,
      .dmm-container--background-blue & {
        background-color: $primary-color-white;
      }
    }
  }

  &:focus {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $primary-color-black;
      border: 1px solid $primary-color-black;

      .cmp-carousel__action-icon:before {
        background-color: $primary-color-white;
      }

      .dmm-container--background-dark &,
      .dmm-container--background-blue & {
        background-color: $primary-color-white;
        border: 1px solid $primary-color-white;

        .cmp-carousel__action-icon:before {
          background-color: $primary-color-black;
        }
      }
    }
  }
}

.cmp-carousel__action--disabled {
}

.cmp-carousel__action--previous {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjIxNTUgMjRMMjguNzA3NSAzMi40OTI1QzI4Ljg5NDggMzIuNjc5NSAyOC45OTQ4IDMyLjkwOSAyOS4wMDc1IDMzLjE4MUMyOS4wMjA1IDMzLjQ1MjcgMjguOTIwNSAzMy42OTQ4IDI4LjcwNzUgMzMuOTA3NUMyOC40OTQ4IDM0LjEyMDUgMjguMjU5IDM0LjIyNyAyOCAzNC4yMjdDMjcuNzQxIDM0LjIyNyAyNy41MDUxIDM0LjEyMDUgMjcuMjkyNSAzMy45MDc1TDE4LjUxNTUgMjUuMTMxQzE4LjM0MTEgMjQuOTU2MyAxOC4yMTg2IDI0Ljc3OCAxOC4xNDggMjQuNTk2QzE4LjA3NzYgMjQuNDE0IDE4LjA0MjUgMjQuMjE1MyAxOC4wNDI1IDI0QzE4LjA0MjUgMjMuNzg0NyAxOC4wNzc2IDIzLjU4NiAxOC4xNDggMjMuNDA0QzE4LjIxODYgMjMuMjIyIDE4LjM0MTEgMjMuMDQzNyAxOC41MTU1IDIyLjg2OUwyNy4yOTI1IDE0LjA5MjVDMjcuNDc5NSAxMy45MDUyIDI3LjcwOSAxMy44MDUyIDI3Ljk4MSAxMy43OTI1QzI4LjI1MjYgMTMuNzc5NSAyOC40OTQ4IDEzLjg3OTUgMjguNzA3NSAxNC4wOTI1QzI4LjkyMDUgMTQuMzA1MiAyOS4wMjcgMTQuNTQxIDI5LjAyNyAxNC44QzI5LjAyNyAxNS4wNTkgMjguOTIwNSAxNS4yOTQ4IDI4LjcwNzUgMTUuNTA3NUwyMC4yMTU1IDI0WiIgZmlsbD0iIzIxMjEyNCIvPgo8L3N2Zz4K');
      mask-size: cover;
    }
  }
}

.cmp-carousel__action--next {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4LjU4NDQgMjRMMjAuMDkyNCAxNS41MDc1QzE5LjkwNTEgMTUuMzIwNSAxOS44MDUxIDE1LjA5MSAxOS43OTI0IDE0LjgxOUMxOS43Nzk0IDE0LjU0NzMgMTkuODc5NCAxNC4zMDUyIDIwLjA5MjQgMTQuMDkyNUMyMC4zMDUxIDEzLjg3OTUgMjAuNTQwOSAxMy43NzMgMjAuNzk5OSAxMy43NzNDMjEuMDU4OSAxMy43NzMgMjEuMjk0OCAxMy44Nzk1IDIxLjUwNzQgMTQuMDkyNUwzMC4yODQ0IDIyLjg2OUMzMC40NTg4IDIzLjA0MzcgMzAuNTgxMyAyMy4yMjIgMzAuNjUxOSAyMy40MDRDMzAuNzIyMyAyMy41ODYgMzAuNzU3NSAyMy43ODQ3IDMwLjc1NzUgMjRDMzAuNzU3NSAyNC4yMTUzIDMwLjcyMjMgMjQuNDE0IDMwLjY1MTkgMjQuNTk2QzMwLjU4MTMgMjQuNzc4IDMwLjQ1ODggMjQuOTU2MyAzMC4yODQ0IDI1LjEzMUwyMS41MDc0IDMzLjkwNzVDMjEuMzIwNCAzNC4wOTQ4IDIxLjA5MDkgMzQuMTk0OCAyMC44MTg5IDM0LjIwNzVDMjAuNTQ3MyAzNC4yMjA1IDIwLjMwNTEgMzQuMTIwNSAyMC4wOTI0IDMzLjkwNzVDMTkuODc5NCAzMy42OTQ4IDE5Ljc3MjkgMzMuNDU5IDE5Ljc3MjkgMzMuMkMxOS43NzI5IDMyLjk0MSAxOS44Nzk0IDMyLjcwNTIgMjAuMDkyNCAzMi40OTI1TDI4LjU4NDQgMjRaIiBmaWxsPSIjMjEyMTI0Ii8+Cjwvc3ZnPgo=');
      mask-size: cover;
    }
  }
}

.cmp-carousel__action--pause {
  display: none;
}

.cmp-carousel__action--play {
  display: none;
}

.cmp-carousel__action-icon {
}

.cmp-carousel__action-text {
  display: none;
}

.cmp-carousel__indicators {
  height: 20px;
  visibility: hidden;
}

.cmp-carousel__indicator {
  width: 2.5rem;
  height: 0.125rem;
  margin: auto 0.125rem 0 0.125rem;
  background-color: $color-shade-1;
  border-radius: 0.125rem;
}

.cmp-carousel__indicator--active {
  background-color: $secondary-color-blue;
  height: rem(5)
}
