.teaser--management,
.teaser--management-extended {
  &.teaser--reverse {
    .cmp-teaser__image {
      order: 2;
    }

    .cmp-teaser__content {
      order: 1;
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto rem(24);
    gap: 28.76px;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
  }

  .cmp-teaser__image {
    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      object-fit: cover;
      border-radius: $border-radius--small;
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 rem(24);
    color: $text-color-black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    order: 2;
    cursor: pointer;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: rem(32);
      height: rem(32);
      background-color: $icon-dark;
      mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb25zL3BsdXMiPgo8cGF0aCBpZD0iaWNvbiIgZD0iTTIzIDI1SDEzQzEyLjcxNjcgMjUgMTIuNDc5MiAyNC45MDQgMTIuMjg3NSAyNC43MTJDMTIuMDk1OCAyNC41MiAxMiAyNC4yODIyIDEyIDIzLjk5ODVDMTIgMjMuNzE0OCAxMi4wOTU4IDIzLjQ3NzUgMTIuMjg3NSAyMy4yODY1QzEyLjQ3OTIgMjMuMDk1NSAxMi43MTY3IDIzIDEzIDIzSDIzVjEzQzIzIDEyLjcxNjcgMjMuMDk2IDEyLjQ3OTIgMjMuMjg4IDEyLjI4NzVDMjMuNDggMTIuMDk1OCAyMy43MTc4IDEyIDI0LjAwMTUgMTJDMjQuMjg1MiAxMiAyNC41MjI1IDEyLjA5NTggMjQuNzEzNSAxMi4yODc1QzI0LjkwNDUgMTIuNDc5MiAyNSAxMi43MTY3IDI1IDEzVjIzSDM1QzM1LjI4MzMgMjMgMzUuNTIwOCAyMy4wOTYgMzUuNzEyNSAyMy4yODhDMzUuOTA0MiAyMy40OCAzNiAyMy43MTc4IDM2IDI0LjAwMTVDMzYgMjQuMjg1MiAzNS45MDQyIDI0LjUyMjUgMzUuNzEyNSAyNC43MTM1QzM1LjUyMDggMjQuOTA0NSAzNS4yODMzIDI1IDM1IDI1SDI1VjM1QzI1IDM1LjI4MzMgMjQuOTA0IDM1LjUyMDggMjQuNzEyIDM1LjcxMjVDMjQuNTIgMzUuOTA0MiAyNC4yODIyIDM2IDIzLjk5ODUgMzZDMjMuNzE0OCAzNiAyMy40Nzc1IDM1LjkwNDIgMjMuMjg2NSAzNS43MTI1QzIzLjA5NTUgMzUuNTIwOCAyMyAzNS4yODMzIDIzIDM1VjI1WiIgZmlsbD0iIzIxMjEyNCIvPgo8L2c+Cjwvc3ZnPgo=");
      mask-size: cover;

      @media screen and (min-width: $breakpoint--tablet-new) {
        width: rem(48);
        height: rem(48);
      }
    }

    &.opened {
      &:after {
        mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxMi41QzYuMzU4MzMgMTIuNSA2LjIzOTU4IDEyLjQ1MiA2LjE0Mzc1IDEyLjM1NkM2LjA0NzkyIDEyLjI2IDYgMTIuMTQxMSA2IDExLjk5OTNDNiAxMS44NTc0IDYuMDQ3OTIgMTEuNzM4OCA2LjE0Mzc1IDExLjY0MzNDNi4yMzk1OCAxMS41NDc4IDYuMzU4MzMgMTEuNSA2LjUgMTEuNUgxNy41QzE3LjY0MTcgMTEuNSAxNy43NjA0IDExLjU0OCAxNy44NTYyIDExLjY0NEMxNy45NTIxIDExLjc0IDE4IDExLjg1ODkgMTggMTIuMDAwOEMxOCAxMi4xNDI2IDE3Ljk1MjEgMTIuMjYxMiAxNy44NTYyIDEyLjM1NjdDMTcuNzYwNCAxMi40NTIyIDE3LjY0MTcgMTIuNSAxNy41IDEyLjVINi41WiIgZmlsbD0iIzIxMjEyNCIvPgo8L3N2Zz4K");
        mask-size: cover;
      }

      .cmp-teaser__description {
        &.opened {
          display: block;
        }
      }
    }

    .cmp-teaser__pretitle {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      color: $text-color-medium-grey;
      text-transform: none;
      text-decoration: none;
      line-height: 130%;
      margin: 0 rem(32) 0 0;
      order: 2;
      cursor: auto;

      @media screen and (min-width: $breakpoint--tablet-new) {
        font-size: rem(23);
        letter-spacing: 0.115px;
        margin: rem(5) rem(52) 0 0;
      }
    }

    .cmp-teaser__title,
    .cmp-teaser__title a {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      color: $text-color-black;
      text-transform: none;
      text-decoration: none;
      line-height: 130%;
      margin: 0 rem(32) 0 0;
      order: 1;

      @media screen and (min-width: $breakpoint--tablet-new) {
        font-size: rem(23);
        letter-spacing: 0.115px;
        margin: rem(5) rem(52) 0 0;
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      display: -webkit-box;
      font-size: rem(14);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.07px;
      margin: 0;
      order: 3;
      cursor: auto;

      @media screen and (min-width: $breakpoint--tablet-new) {
        font-size: rem(16);
        letter-spacing: 0.08px;
      }
    }

    .cmp-teaser__description {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out;

      &.opened {
        max-height: 1000px; // Large enough to accommodate any content
        margin: rem(25) 0 0;
        opacity: 1;
      }
    }
  }

  .cmp-teaser__action-container {
    @media screen and (min-width: $breakpoint--tablet-new) {
      margin: rem(16) 0 0;
    }
  }

  .cmp-teaser__action-link {
    @extend %cta;

    &:first-child {
      @extend %cta--secondary;
    }

    &:nth-child(2) {
      @extend %cta--quiet;

      &:before {
        position: absolute;
        content: "";
        top: rem(8);
        right: rem(10);
        height: rem(24);
        width: rem(24);
        background-image: $icon-quit-black;
      }
    }

    &:first-child,
    &:nth-child(2) {
      margin-bottom: 0;
      padding: rem(12) rem(40);
    }
  }
}

.teaser--management-extended {
  .cmp-teaser {
    @media screen and (min-width: $breakpoint--tablet-new) {
      max-width: rem(300);
    }
  }

  .cmp-teaser__image {
    & img {
      height: rem(359);

      @media screen and (min-width: $breakpoint--tablet-new) {
        height: rem(300);
      }
    }
  }
}
