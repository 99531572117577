.main-header {
  position: fixed;
  top: 0;
  max-width: rem(1920);
  z-index: 1000;
}

.main-header {
  background-color: transparent;
  transition: background-color 0.5s ease;

  ._js-main-nav--open &,
  &.scrolled {
    background-color: $primary-color-white;

    @media screen and (min-width: $breakpoint--desktop) {
      background-color: transparent;
    }

    .cmp-experiencefragment--header {
      .cmp-container {
        /* White logo - switch to black logo, i.e. second image on mobile */
        &[role=white-header] {
          .image:nth-child(1) {
            display: none;
          }

          .image:nth-child(2) {
            display: flex;
          }

          #mobile-header-button {
            &:before {
              background-image: $icon-menu;
            }

            &._js-openbtn:before {
              background-image: $icon-close;
            }
          }
        }
      }
    }
  }

  .cmp-experiencefragment--header {
    color: $text-color-black;
    padding: 1rem;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    min-height: rem(35);

    .cmp-image {
      margin: 0;

      &__image {
        margin: rem(2.5) rem(40) 0 0;
        width: 10.8125rem;
        min-width: 10.8125rem;
        left: rem(0);

        @media screen and (min-width: $breakpoint--desktop) {
          position: absolute;
          display: flex;
          align-items: center;
          margin: rem(16) rem(40) 0 0;
          left: rem(70);
        }

        @media screen and (min-width: $breakpoint--desktop-large) {
          width: 13.3125rem;
        }
      }

    }

    .cmp-container {
      display: flex;
      flex-direction: column;
      position: relative;

      @media screen and (min-width: $breakpoint--desktop) {
        justify-content: flex-start;
      }

      /* White logo - switch to black logo, i.e. second image on mobile */
      &[role=white-header] {
        .image:nth-child(1) {
          display: flex;
        }

        .image:nth-child(2) {
          display: none;
        }

        &._js-open {
          .image:nth-child(1) {
            display: none;
          }

          .image:nth-child(2) {
            display: flex;
          }
        }
      }

      .image,
      .navigation,
      .button {
        display: flex;
        float: none;
      }

      .image {
        justify-content: flex-start;
      }

      .navigation {
        display: none;
        justify-content: flex-start;
        flex-grow: 1;

        @media screen and (min-width: $breakpoint--desktop) {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 auto;
        }

        @media screen and (min-width: $breakpoint--tablet-new) and (max-width: 1200px) {
          margin: unset;
          margin-left: auto;
        }
      }

      .button {
        display: none;
        justify-content: flex-end;

        @media screen and (min-width: $breakpoint--desktop) {
          display: block;
        }
      }

      &._js-open {
        height: calc(100dvh - 1rem);

        .navigation,
        .button {
          display: flex;
          flex-direction: column;
        }

        .navigation {
          order: 3;

          .cmp-navigation__group {
            align-content: normal;
          }
        }

        .button {
          justify-content: flex-start;
          margin-top: 2rem;
          position: relative;

          .cmp-button {
            flex-grow: 1;
          }
        }

        .breadcrumb {
          display: none;
        }

        #mobile-header-button {
          display: block;
        }
      }
    }
  }

  .cmp-experiencefragment--header .image {
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    opacity: 1;
    visibility: visible;
  }

  .cmp-experiencefragment--header .image.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .cmp-experiencefragment--header.submenu-hide-breadcrumb {
    .cmp-navigation__item.has-submenu.opened > .cmp-navigation__group {
      top: calc(100% - 36px);
    }
  }

  #mobile-header-button {
    background-color: transparent;
    border: none;
    display: block;
    padding-block: 0;
    padding-inline: 0;
    padding: 0;
    position: absolute;
    right: 0;
    cursor: pointer;

    &:before {
      background-color: transparent;
      background-image: $icon-menu;
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 24px;
      vertical-align: middle;
      width: 26px;
    }

    &._js-openbtn:before {
      background-image: $icon-close;
    }

    @media screen and (min-width: $breakpoint--desktop) {
      display: none;
    }
  }

  .cmp-container {
    &[role=white-header] {
      #mobile-header-button {
        &:before {
          background-image: $icon-menu-white;
        }

        &._js-openbtn:before {
          background-image: $icon-close;
        }
      }
    }
  }

  #search-header-button {
    display: none;

    @media screen and (min-width: $breakpoint--desktop) {
      display: block;
      background-color: transparent;
      border: none;
      padding: rem(2) 0 0 rem(8);
      position: relative;
      cursor: pointer;

      &:before {
        background-color: transparent;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjE1NTYgMTQuODY5N0M4LjU1Njg5IDE0Ljg2OTcgNy4yMDIwNSAxNC4zMTQzIDYuMDkxMDUgMTMuMjAzNUM0Ljk4MDIyIDEyLjA5MjUgNC40MjQ4IDEwLjczNzYgNC40MjQ4IDkuMTM4OTVDNC40MjQ4IDcuNTQwMjkgNC45ODAyMiA2LjE4NTQ1IDYuMDkxMDUgNS4wNzQ0NUM3LjIwMjA1IDMuOTYzNjIgOC41NTY4OSAzLjQwODIgMTAuMTU1NiAzLjQwODJDMTEuNzU0MiAzLjQwODIgMTMuMTA5MSAzLjk2MzYyIDE0LjIyMDEgNS4wNzQ0NUMxNS4zMzA5IDYuMTg1NDUgMTUuODg2MyA3LjU0MDI5IDE1Ljg4NjMgOS4xMzg5NUMxNS44ODYzIDkuODMzNzkgMTUuNzYzMiAxMC41MDYyIDE1LjUxNzEgMTEuMTU2MkMxNS4yNzA5IDExLjgwNjIgMTQuOTQ3OCAxMi4zNjIgMTQuNTQ3OCAxMi44MjM1TDIwLjQ1NTYgMTguNzMxMkMyMC41NDkxIDE4LjgyNDkgMjAuNTk5MSAxOC45Mzk2IDIwLjYwNTYgMTkuMDc1NUMyMC42MTE5IDE5LjIxMTUgMjAuNTYxOSAxOS4zMzI2IDIwLjQ1NTYgMTkuNDM5QzIwLjM0OTEgMTkuNTQ1MyAyMC4yMzExIDE5LjU5ODUgMjAuMTAxNiAxOS41OTg1QzE5Ljk3MjIgMTkuNTk4NSAxOS44NTQzIDE5LjU0NTMgMTkuNzQ3OCAxOS40MzlMMTMuODQwMSAxMy41MzEyQzEzLjM0MDEgMTMuOTU2OSAxMi43NjUxIDE0LjI4NjQgMTIuMTE1MSAxNC41MTk3QzExLjQ2NTEgMTQuNzUzIDEwLjgxMTkgMTQuODY5NyAxMC4xNTU2IDE0Ljg2OTdaTTEwLjE1NTYgMTMuODY5N0MxMS40ODI0IDEzLjg2OTcgMTIuNjAyNiAxMy40MTMgMTMuNTE2MSAxMi40OTk1QzE0LjQyOTYgMTEuNTg2MSAxNC44ODYzIDEwLjQ2NiAxNC44ODYzIDkuMTM4OTVDMTQuODg2MyA3LjgxMTk1IDE0LjQyOTYgNi42OTE3OSAxMy41MTYxIDUuNzc4NDVDMTIuNjAyNiA0Ljg2NDk1IDExLjQ4MjQgNC40MDgyIDEwLjE1NTYgNC40MDgyQzguODI4NTYgNC40MDgyIDcuNzA4MzkgNC44NjQ5NSA2Ljc5NTA1IDUuNzc4NDVDNS44ODE1NSA2LjY5MTc5IDUuNDI0OCA3LjgxMTk1IDUuNDI0OCA5LjEzODk1QzUuNDI0OCAxMC40NjYgNS44ODE1NSAxMS41ODYxIDYuNzk1MDUgMTIuNDk5NUM3LjcwODM5IDEzLjQxMyA4LjgyODU2IDEzLjg2OTcgMTAuMTU1NiAxMy44Njk3WiIgZmlsbD0iIzIxMjEyNCIvPgo8L3N2Zz4K");
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: inline-block;
        height: 24px;
        vertical-align: middle;
        width: 26px;
      }
    }
  }
}