.teaser--small-round {
  &.teaser--reverse {
    .cmp-teaser__image {
      order: 2;
    }

    .cmp-teaser__content {
      order: 1
    }
  }

  .cmp-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: rem(288);
    margin: 0 auto;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }
  }

  .cmp-teaser__link {
    text-decoration: none;
  }

  .cmp-teaser__image {
    margin: 0 auto;

    & .cmp-image {
      margin: 0;
    }

    & img {
      display: block;
      width: rem(180);
      height: rem(180);
      object-fit: cover;
      border-radius: 100%;

      @media screen and (min-width: $breakpoint--tablet-new) {
      }
    }
  }

  .cmp-teaser__content {
    display: flex;
    flex-direction: column;
    padding: rem(24) rem(16);
    margin: 0 auto;
    color: $text-color-black;
    gap: 16px;
    order: 2;

    @media screen and (min-width: $breakpoint--tablet-new) {
    }

    .cmp-teaser__pretitle {}

    .cmp-teaser__title,
    .cmp-teaser__title a {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: $text-color-black;
      text-transform: none;
      text-decoration: none;
      margin: 0;

      @media screen and (min-width: $breakpoint--tablet-new) {
        font-size: rem(23);
      }
    }

    .cmp-teaser__description,
    .cmp-teaser__description p {
      display: -webkit-box;
      height: 40px;
      font-size: rem(14);
      font-style: normal;
      font-weight: 340;
      line-height: 150%;
      letter-spacing: 0.07px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint--tablet-new) {
        height: 50px;
        font-size: rem(16);
        letter-spacing: 0.08px;
      }
    }
  }

  .cmp-teaser__action-container {
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: $breakpoint--tablet-new) {
      margin-top: rem(16);
    }
  }

  .cmp-teaser__action-link {
    @extend %cta;

    &:first-child,
    &:nth-child(2) {
      margin-bottom: 0;
      padding: rem(12) rem(40);
    }
  }
}