.breadcrumb {
  .cmp-breadcrumb {
    margin: 1.5rem 0 0;

    @media screen and (min-width: $breakpoint--desktop) {
      margin: 1.5rem 0 0 rem(70);
    }
  }

  .cmp-breadcrumb__list {
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  .cmp-breadcrumb__item {
    list-style-type: none;
    width: fit-content;
    font-size: rem(13);
    font-style: normal;
    font-weight: 340;
    line-height: 150%; /* 19.5px */
    letter-spacing: 0.065px;
  }

  .cmp-breadcrumb__item:not(:last-of-type) {
    &:after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0;
      vertical-align: -35%;
      background-image: $icon-chevron-right;
      pointer-events: none;
    }
  }

  .cmp-breadcrumb__item--active {
    color: $secondary-color-blue;
    line-height: 1.5rem;
    border: 0.125rem solid $color-transparent;
  }

  .cmp-breadcrumb__item-link {
    color: $primary-color-black;
    text-decoration: none;
    border: 0.125rem solid $color-transparent;
    border-radius: 0.25em;

    &:hover {
      border-bottom: 1px solid $border-colors-dark;
    }

    &:focus {
      padding: rem(8);
      border: 2px solid $border-colors-dark;
      border-radius: rem(8);
      background: $primary-color-white;
      outline: none;
    }
  }
}
